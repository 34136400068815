import * as React from 'react';
import { useState } from 'react';
import { ImageMedia } from '../../model/images/image-media';
import { Galleria } from 'primereact/galleria';
import { getContentUrl } from '../content/helpers';
import { getMediaImages, selectRandomImages } from '../../model/images/images-api';

interface RandomImageGalleryProps {
    imageListUri: string;
}

const RandomImageGallery = (props: RandomImageGalleryProps): JSX.Element => {

    const [displayedImages, setDisplayedImages] = useState<Array<ImageMedia>>();

    React.useEffect(() => {

        (async () => {

            const data = await getMediaImages(props.imageListUri);

            const random = selectRandomImages(data, 3);

            setDisplayedImages(random);
        })();

    }, [props.imageListUri])

    const itemTemplate = (item: ImageMedia) => {
        if (!item || !item.appUri) {
            return <></>;
        }

        const imageHref = getContentUrl(item.href);
        if (imageHref === '') {
            return <></>;
        }

        return (
            <div className='align-content-center'>
                <a href={item.appUri}>
                    <h1>{item.appName}</h1>
                    <img src={imageHref} className='border-round-md border-solid border-primary' alt={item.altText} style={{ width: '80vh' }} />
                </a>
            </div>
        );
    }

    if (!displayedImages || displayedImages.length === 0) {
        return <></>;
    }

    return (

        <Galleria
            value={displayedImages}
            showThumbnails={false}
            showIndicators
            showItemNavigators
            showItemNavigatorsOnHover
            item={itemTemplate}
        />
    );
};

export default RandomImageGallery;