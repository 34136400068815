import React, { ReactNode } from 'react';
import { Card } from "primereact/card";

type TitleProps = {
    title?: string;
    children?: ReactNode
};

const Title = (props: TitleProps): JSX.Element => {

    const renderTitle = (): JSX.Element => {
        if (!props.title) {
            return <></>;
        }

        return <h1 className='text-center'>{props.title}</h1>;
    }

    return (
        <Card className='block md:m-10 m-5'>
            {renderTitle()}
            <div className='text-center'>{props.children}</div>
        </Card>
    );
}

export default Title;