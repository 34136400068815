import Axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import config from '../../config';

import { IOTProject } from './iot';
import iotPage from './iot-page';

type TParams = {
    project: string;
};

const IOT = (): JSX.Element => {

    const [project, setProject] = useState<IOTProject | null>();

    const params = useParams<TParams>();

    useEffect(() => {

        (async () => {
            try {
                const data: AxiosResponse<IOTProject> = await Axios.get(config.dataUrl + '/iot/' + params.project + '.json');

                setProject(data.data);
            }
            catch (e) {
                setProject(null);
            }
        })();
    }, [params.project]);

    if (!project || !project.content) {
        return <div>Retrieving project</div>;
    }

    return iotPage(project);
};

export default IOT;