import * as React from 'react';
import RenderContent from '../../components/content/render-content';
import Sidebar from '../../components/sidebar';
import { IOTProject } from './iot';
import { Fieldset } from 'primereact/fieldset';
import Title from '../../components/content/title';
import MediaGallery from '../../components/content/media-gallery';

const iotPage = (project: IOTProject): JSX.Element => {

    return (
        <div id='content-parent'>

            <Title title={project.info.name}>
            </Title>
            <div id='content' className='flex flex-wrap card-container'>
                <Fieldset legend='Overview' className='flex flex-grow-1 m-2 border-primary justify-content-center'>
                    {RenderContent(project.content, project.media)}
                </Fieldset>

                <Fieldset legend='Media' className='flex flex-grow-1 m-2 border-primary justify-content-center'>
                    <MediaGallery media={project.media} />
                </Fieldset>

                <Fieldset legend='Info' className='flex flex-none m-2 align-self-start border-primary'>
                    {Sidebar(project)}
                </Fieldset>

            </div>
        </div>
    );
};

export default iotPage;
