import React from "react";
import Title from "../../components/content/title";
import { Print3DProject } from "./3d-print";
import { Fieldset } from "primereact/fieldset";
import RenderContent from "../../components/content/render-content";
import MediaGallery from "../../components/content/media-gallery";
import Sidebar from "../../components/sidebar";
import RenderSTLContent from "../../components/content/render-stl";

const Print3DPage = (project: Print3DProject): JSX.Element => {

    const renderSTL = () => {

        if (!project.stl) {
            return <></>;
        }

        return (
            <Fieldset legend='Model' className='flex flex-grow-1 m-2 border-primary justify-content-center'>
                {RenderSTLContent(project.stl)}
            </Fieldset>
        );
    };

    return (
        <div id='content-parent'>

            <Title title={project.info.name}>
            </Title>
            <div id='content' className='flex flex-wrap card-container'>
                <Fieldset legend='Overview' className='flex flex-grow-1 m-2 border-primary justify-content-center'>
                    {RenderContent(project.content, project.media)}
                </Fieldset>

                {renderSTL()}

                <Fieldset legend='Media' className='flex flex-grow-1 m-2 border-primary justify-content-center'>
                    <MediaGallery media={project.media} />
                </Fieldset>

                <Fieldset legend='Info' className='flex flex-none m-2 align-self-start border-primary'>
                    {Sidebar(project)}
                </Fieldset>

            </div>
        </div>
    );
};

export default Print3DPage;
