import * as React from 'react';
import { useEffect, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';

import Timeline from '../components/timeline/timeline';
import { TimelineData } from '../model/timeline/timeline-data';
import { getTimelineData } from '../model/timeline/timeline-api';
import Title from '../components/content/title';
import RandomImageGallery from '../components/gallery/random-image-gallery';

const Home = (): JSX.Element => {

    const [timeline, setTimeline] = useState<TimelineData | null>();

    useEffect(() => {
        (async () => {
            const timelineData = await getTimelineData();
            setTimeline(timelineData);
        })()
    }, []);

    const renderTimeline = (): JSX.Element | null => {

        return (
            <div style={{ maxWidth: '80vw', minWidth: '350px' }}>
                {Timeline(timeline)}
            </div>
        );
    };

    return (
        <>
            <Title>
                I'm Phil, and I am a software developer with a passion for creating solutions in games, internet of Things (IoT), 3D printing and various other projects.
            </Title>
            <div className='flex flex-wrap card-container overflow-hidden'>
                <div className='flex md:m-2 flex-grow-1' >
                    <Fieldset legend='Random projects' className='border-primary w-full'>
                        <p>A selection of the various projects I have worked on over the years.</p>
                        <RandomImageGallery imageListUri='./images.json' />
                    </Fieldset>
                </div>
                <div className='flex-none flex md:m-2' >
                    <div>
                        <Fieldset legend='Updates' className='border-primary'>
                            {renderTimeline()}
                        </Fieldset>
                    </div>
                </div>
            </div>
        </>
    );

};

export default Home;