import * as React from 'react';

import { Menubar } from 'primereact/menubar';
import { MenuItem as PrimeReactMenuItem } from 'primereact/menuitem';
import { MenuData, MenuItem } from '../model/menu/menu-types';

export type MenuProps = {
    data: MenuData;
};

const WebHeader = (props: MenuProps): JSX.Element => {

    const menuItemToPrimeReactMenuItem = (item: MenuItem): PrimeReactMenuItem => {
        return {
            label: item.name,
            icon: <i className="material-icons menu-icon">{item.icon}</i>,
            url: item.href,
            target: item.openNew ? '_blank' : '_self'
        };
    }

    const menuItems = props.data.map((itemGroup) => {
        const menuGroup: PrimeReactMenuItem = {
            label: itemGroup.parent.name,
            icon: <i className="material-icons">{itemGroup.parent.icon}&nbsp;</i>
        };

        if (itemGroup.children.length > 0) {        
            menuGroup.items = itemGroup.children.map(menuItemToPrimeReactMenuItem);
        } else {
            menuGroup.url = itemGroup.parent.href;
        }
        
        return menuGroup;
    });

    return (
        <Menubar model={menuItems} className='shadow-6 md:fixed right z-5' />
    );
};

export default WebHeader;