import * as React from 'react';

import { BlogContent, Content } from "./content";

const renderBlogContent = (item: Content<BlogContent>, key: number): JSX.Element => {

    const renderDate = (date: Date): JSX.Element => {

        const month: string = date.toLocaleDateString(undefined, {
            month: 'short'
        });
        
        const year: string = date.toLocaleDateString(undefined, {
            year: 'numeric'
        });

        return (
            <div className='date'>
                <div className='month'>{month}</div>
                <div className='year'>{year}</div>
            </div>
        );
    };

    return (
        <div className={item.type}>  
            <p key={key}>
                {renderDate(new Date(item.data.date))}&nbsp;
                {item.text}
            </p>

            <hr />
        </div>
    )
};

export default renderBlogContent;