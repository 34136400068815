import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios, { AxiosResponse } from 'axios';

import config from '../../config';

import GamePage from './game-page';
import {Game} from './game';

type TParams = {
    game: string;
};

const Games = (): JSX.Element => {

    const [game, setGame] = useState<Game | null>();

    const params = useParams<TParams>();

    useEffect(() => {

        (async () => {
            try {
                const gameData: AxiosResponse<Game> = await Axios.get(config.dataUrl + '/game/' + params.game + '.json');

                setGame(gameData.data);
            }
            catch (e) {
                setGame(null);
            }
        })();
    }, [params.game]);

    if (!game || !game.content) {
        return <div>Retrieving game</div>;
    }

    return GamePage(game);
};

export default Games;