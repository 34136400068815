import * as React from 'react';
import { TimelineItem } from '../content/content';

import {Timeline as PrimeReactTimeline} from 'primereact/timeline';

const PageTimeline = (timeline: Array<TimelineItem>): JSX.Element => {

    const item = (item: TimelineItem) => item.text;
    const dateString = (item: TimelineItem) => {
        const date = new Date(item.date);
        
        const MonthName = ['January', 'February', 'March', 
                        'April', 'May', 'June', 
                        'July', 'August', 'September',
                        'October', 'November', 'December'];

        return `${MonthName[date.getMonth()]} ${date.getFullYear()}`;
    }

    return (
        <PrimeReactTimeline value={timeline} className='customized-timeline' content={item} opposite={dateString} />
    );
}

export default PageTimeline;