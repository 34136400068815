import React from 'react';
import { Divider } from 'primereact/divider';

import {Game} from '../../pages/games/game.d';
import {stringArrayToList, linkArrayToList} from '../../components/content/helpers';

import PageTimeline from './page-timeline';
import { Card } from 'primereact/card';

const Sidebar = (game: Game): JSX.Element => {

    const platform = (): JSX.Element => {
        return (
            <Card title='Platforms'>
                {stringArrayToList(game.info.platforms)}
            </Card>
        );      
    }

    const timeline = (): JSX.Element | null => {
        if (game.info.timeline.length === 0) {
            return null;
        }
        
        return (
            <Card title='Timeline'>
                {PageTimeline(game.info.timeline)}
            </Card>
        );
    };

    const links = (): JSX.Element | null => {
        if (game.links.length === 0) {
            return null;
        }
        
        return (
            <Card title='Links'>
                {linkArrayToList(game.links)}
            </Card>
        );
    }

    return (
        <div id='sidebar'>
            {platform()}

            <Divider />

            {timeline()}

            <Divider />

            {links()}
            
        </div>
    );
};

export default Sidebar;