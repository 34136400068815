import Axios from "axios";
import { ImageMedia } from "./image-media";
import config from "../../config";

export const getMediaImages = async (uri: string): Promise<Array<ImageMedia>> => {
    const imageResp = await Axios.get<Array<ImageMedia>>(`${config.dataUrl}/${uri}`);
    return imageResp.data;    
};

export const selectRandomImages = (images: Array<ImageMedia>, numImages: number): Array<ImageMedia> => {
    
    if (images.length <= numImages) {
        return images;
    }

    const randomImages = [];
    for (let i = 0; i < numImages; ++i) {
        const index = Math.floor(Math.random() * images.length);
        const image = images.splice(index, 1)[0];
        randomImages.push(image);
    }

    return randomImages;
};