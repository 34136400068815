import * as React from 'react';
import { useEffect, useState } from 'react';

import {Timeline as PrimeReactTimeline} from 'primereact/timeline';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { TimelineData, TimelineDateData, TimelineItem } from '../../model/timeline/timeline-data';

const Timeline = (data?: TimelineData | null): JSX.Element => {

    //const [selectedItem, selectItem] = useState<number | null>(null);
    const [allYears, setAllYears] = useState<Array<TimelineDateData>>([]); 
    
    useEffect(() => {

        if (!data) {
            return;
        }
        
        // get unique years
        let activeYear: number | null = null;
        let index = 0;
        const allUnique: Array<TimelineDateData> = data.reduce((prev: Array<TimelineDateData>, cur: TimelineItem) => {

            const itemYear: number = new Date(cur.date).getFullYear();
            if (activeYear !== itemYear) {

                // create a new date item
                prev.push({
                    index: index,
                    date: new Date(cur.date),
                    items: [cur]
                });

                ++index;
                activeYear = itemYear;
            } else {
                // add to active date item
                prev[prev.length - 1].items.push(cur)
            }
            return prev;
        }, []);

        setAllYears(allUnique);

    }, [data]);

    const renderItem = (item: TimelineItem): JSX.Element => {

        return (
            <>
                {item.url ? 
                    <span><a href={item.url}>{item.name}</a></span>
                    : 
                    <span>{item.name}</span>
                }
                <p className='p-text-secondary'>{item.text}</p>
            </>
        );
    }

    const renderMonthName = (item: TimelineItem): JSX.Element => {
        const MonthName = ['January', 'February', 'March', 
                        'April', 'May', 'June', 
                        'July', 'August', 'September',
                        'October', 'November', 'December'];

        const month = MonthName[new Date(item.date).getMonth()];

        return (
            <span>{month}</span>
        );
    }    

    const renderYear = (year: TimelineDateData): JSX.Element => {

        const plural = year.items.length > 1 ? 's' : '';
        const itemsText = `(${year.items.length} item${plural})`

        const renderHeader = (): JSX.Element => {
            return (
                <p>
                    {year.date.getFullYear()}
                    &nbsp;&nbsp;
                    <span className='text-xs'>{itemsText}</span>
                </p>
            );
        }
        
        return (    
            <AccordionTab header={renderHeader()} key={year.date.toString()}>
                <PrimeReactTimeline 
                    value={year.items} 
                    className='customized-timeline align-self-start flex-grow-1 flex' 
                    opposite={renderMonthName}
                    content={renderItem} />
            </AccordionTab>     
        );
    }
    
    if (!data) {
        return <></>;
    }
    
    return (

        <Accordion multiple={true} activeIndex={[0]}>
            {allYears.map(renderYear)}
        </Accordion>
    );
};

export default Timeline;