import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import * as Axios from 'axios';

// pages
import Home from './pages/home';
import Games from './pages/games';
import IOT from './pages/iot';

import './app.scss';
import 'primereact/resources/themes/viva-dark/theme.css';
import "primereact/resources/primereact.min.css"; 
import 'primeflex/primeflex.min.css';

import { getMenuData } from './model/menu/menu-api';
import { MenuData } from './model/menu/menu-types';
import NavigationHeader from './components/navigation-header';
import Print3D from './pages/3d-printing';

Axios.default.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

function App() {

  const [menuData, setMenuData] = useState<MenuData | null>(null);

  useEffect(() => {
    
    (async () => {
      const data = await getMenuData();
      setMenuData(data);
    })();
  }, []);

  if (!menuData) {
    return (
      <div className="App">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="App">
      <NavigationHeader data={menuData} />
      <div className='p-2 md:p-6' />
      
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/game/:game' element={<Games />} />
          <Route path='/iot/:project' element={<IOT />} />
          <Route path='/3d-printing/:project' element={<Print3D />} />
        </Routes>
    </div>
  );
}

export default App;
