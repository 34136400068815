
export type Content<Type> = {
    type: string;
    text: string;
    data: Type;
};

export type BlogContent = {
    date: Date;
    media: Array<string>;
};

export enum MediaType {
    Image = 'image',
    Video = 'video',
    Stl = 'stl'
}

export type Print3DContent = {
    stlUrl: string;
};

export type Media = {
    id: string;
    type: MediaType;
    href: string;
    thumb_href: string;
    altText: string;
};

export type Link = {
    text: string;
    url: string;
};

export type TimelineItem = {
    text: string;
    date: Date;
};

export type Info = {
    name: string;
    platforms: Array<string>;
    download: string;
    timeline: Array<TimelineItem>;
};
