import React from 'react';

import config from '../../config';
import { Link, Media } from './content';

const stringArrayToList = (text: Array<string>, key?: string): JSX.Element => {
    return (
        <ul>
            {text.map((t, index) => <li key={`${key ?? 'key'}_${index}`}>{t}</li>)}
        </ul>
    );
}

const linkArrayToList = (links: Array<Link>): JSX.Element => {

    const renderLink = (link: Link): JSX.Element => {
        if (!link.url) {
            return <span>{link.text}</span>
        }

        return <a href={link.url} target='top'>{link.text}</a>;
    }

    return (
        <ul>
            {links.map((t, index) => (
                <li key={`${'link'}_${index}`}>
                   {renderLink(t)}
                </li>
            ))}
        </ul>
    )
}

const getImagesFromMedia = (media: Array<Media>): Array<Media> => {
    return media.filter(m => m.type === 'image');
}

const getMediaThumbUrl = (media: Media): string => {

    let url = media.thumb_href;
    if (!url || url === '') {
        url = media.href;
    }

    return getContentUrl(url);

}

const getContentUrl = (srcUrl: string): string => {

    if (!srcUrl) {
        return '';
    }

    srcUrl = srcUrl.replace('{content}', config.contentUrl);
    return srcUrl;
}

export {
    stringArrayToList,
    linkArrayToList,
    getMediaThumbUrl,
    getContentUrl,
    getImagesFromMedia
};