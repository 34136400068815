import * as React from 'react';

import { BlogContent, Content, Media, Print3DContent } from "./content.d";
import renderBlogContent from './render-blog';
import renderMediaImageThumb from "./media-image-thumb";
import RenderSTLContent from './render-stl';

const RenderContent = <T, >(content: Array<Content<T>>, availableMedia: Array<Media>): JSX.Element | null => {

    const renderItem = (item: Content<T>, index: number): JSX.Element | null => {
        const key = `${item.text}_${index}`;

        if (item.type === 'media-link') {
            // attempt to find the media within the media section
            const media: Media | undefined = availableMedia.find(m => m.id === item.text);
            if (media) {
                return renderMediaImageThumb(media, index);
            }
        }

        if (item.type === 'blog') {
            return renderBlogContent(item as unknown as Content<BlogContent>, index);
        }

        if (item.type === 'stl') {
            return RenderSTLContent(content as unknown as Content<Print3DContent>);
        }
    
        return (
            <p className={item.type} key={key}>{item.text}</p>
        );
    }

    return (
        <div style={{width: '90vw'}}>
            {content.map(renderItem)}
        </div> 
    );
};

export default RenderContent;