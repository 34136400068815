import React from 'react';
import { Content, Print3DContent } from "./content";
import { StlViewer } from 'react-stl-viewer';
import { getContentUrl } from './helpers';

const RenderSTLContent = (stl: Content<Print3DContent>) => {

    const url = getContentUrl(stl.data.stlUrl);

    const style = {
        top: 0,
        left: 0,
        width: '90vh',
        aspectRatio: 'calc(4/3)',
        border: '1px solid grey',
    };

    return (
        <StlViewer 
            style={style}
            url={url}
            extraHeaders={
                {
                    'content-type': 'application/octet-stream'
                }
            }
            orbitControls
            shadows
        />
    ); 
};

export default RenderSTLContent;