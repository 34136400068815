import Axios, { AxiosResponse } from "axios";
import config from "../../config";
import { MenuData } from "../../model/menu/menu-types";

export const getMenuData = async (): Promise<MenuData | null> => {
    
    try {
        const data: AxiosResponse<MenuData> = await Axios.get(config.dataUrl + '/menu.json');

        return data.data;
    }
    catch (e) {
        return null;
    }
};
