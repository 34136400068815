import Axios, { AxiosResponse } from "axios";
import { TimelineData } from "./timeline-data";
import config from "../../config";

export const getTimelineData = async (): Promise<TimelineData | null> => {

    try {
        const data: AxiosResponse<TimelineData> = await Axios.get(config.dataUrl + '/timeline.json');

        return data.data;
    }
    catch (e) {
        return null;
    }
};