import React from 'react';
import { Galleria } from 'primereact/galleria';
import { Image } from 'primereact/image';

import { Media } from './content';
import { getContentUrl, getImagesFromMedia, getMediaThumbUrl } from './helpers';

type MediaGalleryProps = {
    media: Array<Media>;
}

const MediaGallery = (props: MediaGalleryProps): JSX.Element => {

    const itemTemplate = (item: Media) => {
        return <Image src={getContentUrl(item.href)} alt={item.altText} preview={true} width={'100%'} style={{maxWidth: '50vh'}} />
    }

    const thumbnailTemplate = (item: Media) => {
        return <img src={getMediaThumbUrl(item)} alt={item.altText} style={{ maxHeight: '100px' }} />
    }

    const images = getImagesFromMedia(props.media);
    if (images.length === 1) {
        return itemTemplate(images[0]);
    }

    return (
        <Galleria
            value={images}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
            thumbnailsPosition='top'
            style={{ width: '100%' }} />
    );

};

export default MediaGallery;