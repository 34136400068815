import Axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import config from '../../config';
import { Print3DProject } from './3d-print';
import Print3DPage from './3d-printing-page';


type TParams = {
    project: string;
};

const Print3D = (): JSX.Element => {

    const [project, setProject] = useState<Print3DProject | null>();

    const params = useParams<TParams>();

    useEffect(() => {

        (async () => {
            try {
                const data: AxiosResponse<Print3DProject> = await Axios.get(config.dataUrl + '/3d-printing/' + params.project + '.json');

                const stlMedia = data.data.media.find(item => item.type === 'stl');
                if (stlMedia) {
                    data.data.stl = {
                        type: 'stl',
                        text: stlMedia?.altText,
                        data: {
                            stlUrl: stlMedia.href
                        }
                    }
                }

                setProject(data.data);
            }
            catch (e) {
                setProject(null);
            }
        })();
    }, [params.project]);

    if (!project || !project.content) {
        return <div>Retrieving project</div>;
    }

    return Print3DPage(project);
};

export default Print3D;