import React from 'react';

import { Fieldset } from 'primereact/fieldset';

import Sidebar from '../../components/sidebar';
import {Game} from './game.d';
import RenderContent from '../../components/content/render-content';
import MediaGallery from '../../components/content/media-gallery';
import Title from '../../components/content/title';

const GamePage = (game: Game): JSX.Element => {

    return (
        <div id='content-parent'>

            <Title title={game.info.name}>
            </Title>
            <div id='content' className='flex flex-wrap card-container'>
                <Fieldset legend='Overview' className='flex flex-grow-1 m-2 border-primary justify-content-center'>
                    {RenderContent(game.content, game.media)}
                </Fieldset>

                <Fieldset legend='Media' className='flex flex-grow-1 m-2 border-primary justify-content-center'>
                    <MediaGallery media={game.media} />
                </Fieldset>

                <Fieldset legend='Info' className='flex flex-none m-2 align-self-start border-primary'>
                    {Sidebar(game)}
                </Fieldset>
            </div>
        </div>
    ); 
}

export default GamePage;