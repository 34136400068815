import * as React from 'react';
import { Media, MediaType } from './content.d';
import { getContentUrl, getMediaThumbUrl } from './helpers';
import { Image } from 'primereact/image';

const MediaImageThumb = (item: Media, index: number): JSX.Element | null => {
    const key = `${item.altText}_${index}`;

    if (item.type !== MediaType.Image) {
        return null;
    }

    return (
        <div className='thumbnail-container' key={key}>
            <Image id={'img' + item.id} src={getMediaThumbUrl(item)} className='border-round-md' zoomSrc={getContentUrl(item.href)} alt={item.altText} preview width='250' />
            <div className='font-italic font-light'>{item.altText}</div>
        </div>
    );
};

export default MediaImageThumb;